<template>
  <div class="settings">
    <div class="title">Общее</div>
    <form class="flex" @submit.prevent="save">
      <div class="form-group">
        <label>Email</label>
        <div class="group">
          <input
            type="text"
            :disabled="user.email_verified_at"
            v-model="user.email"
            placeholder="Email"
          />
          <button v-if="!user.email_verified_at" @click="verifyEmail">
            Подтвердить
          </button>
        </div>
      </div>

      <div class="form-group">
        <label>Номер телефона</label>
        <div class="group">
          <input
            type="text"
            v-model="user.info.phone"
            placeholder="Номер телефона"
          />
          <!-- <button>Подтвердить</button> -->
        </div>
        <span v-if="errors.phone" class="text-error">{{ errors.phone }}</span>
      </div>

      <div class="form-group">
        <label>Имя</label>
        <input
          type="text"
          v-model="user.info.first_name"
          class="form-control"
          placeholder="Имя"
        />
        <span v-if="errors.first_name" class="text-error">{{
          errors.first_name
        }}</span>
      </div>

      <div class="form-group">
        <label>Фамилия</label>
        <input
          type="text"
          v-model="user.info.last_name"
          class="form-control"
          placeholder="Фамилия"
        />
        <span v-if="errors.last_name" class="text-error">{{
          errors.last_name
        }}</span>
      </div>

      <div class="form-group">
        <label>Отчество</label>
        <input
          type="text"
          v-model="user.info.second_name"
          class="form-control"
          placeholder="Отчество"
        />
        <span v-if="errors.second_name" class="text-error">{{
          errors.second_name
        }}</span>
      </div>

      <div class="form-group">
        <label>Дата рождения</label>
        <input
          type="date"
          v-model="user.info.birthdate"
          class="form-control"
          placeholder="Дата рождения"
        />
        <span v-if="errors.birthdate" class="text-error">{{
          errors.birthdate
        }}</span>
      </div>

      <div class="form-group">
        <label>Пол</label>
        <select v-model="user.info.sex">
          <option value="null">Не указано</option>
          <option value="1">Мужской</option>
          <option value="2">Женский</option>
        </select>
        <span v-if="errors.sex" class="text-error">{{ errors.sex }}</span>
      </div>

      <div class="form-group">
        <label>Страна</label>
        <Country @setCountry="setCountry" :selectedCountry="selectedCountry" />
      </div>

      <div class="form-group">
        <label>Город</label>
        <City @setCity="setCity" :selectedCity="selectedCity" />
      </div>

      <!-- <div class="form-group">
        <label>Страна</label>
        <select v-model="user.info.country_id">
          <option value="1">Russia Federation</option>
        </select>
      </div>

      <div class="form-group">
        <label>Город</label>
        <select v-model="user.info.city_id">
          <option value="1">Russia Federation</option>
        </select>
      </div> -->

      <button type="submit" class="submit">Сохранить</button>
    </form>

    <div class="title">Привязка соцсетей</div>
    <div class="buttons">
      <!-- Vkontakte -->
      <a
        v-if="providers.vk"
        :href="providers.vk.profile_url"
        target="_blank"
        class="vk"
      >
        <span class="name">Вконтакте</span>
        <span class="description">Привязано</span>
      </a>
      <button v-else class="vk" @click="login('vk')">
        <span class="name">Вконтакте</span>
      </button>

      <!-- Telegram -->
      <a
        v-if="providers.tg"
        :href="providers.tg.profile_url"
        target="_blank"
        class="tg"
      >
        <span class="name">Telegram</span>
        <span class="description">Привязано</span>
      </a>
      <a v-else :href="tg" target="_blank" class="tg">
        <span class="name">Telegram</span>
      </a>
    </div>

    <div v-if="providers.tg" class="fa2">
      <div class="left">
        <img
          alt="Telegram"
          class="icon"
          src="@/assets/icons/profile/bonuses-telegram.png"
        />
        <div class="info">
          <div class="message">Включить двухфакторную аутентификацию</div>
          <div class="description">
            Доступ к вашей учетной записи сможете получить <br />
            только вы с помощью Telegram бота
          </div>
        </div>
      </div>
      <div class="right">
        <div class="name">{{ getTelegramName }}</div>
        <div
          class="toggle"
          :class="{ active: settings['2fa'] }"
          @click="toggle2fa"
        >
          <span></span>
        </div>
      </div>
    </div>

    <div class="title">Валюты</div>

    <div
      class="currency"
      v-for="balance in avaliableBalances"
      :key="balance.name"
    >
      <div class="block">
        <div class="badge primary">{{ balance.icon }}</div>
        <div class="info">
          <div class="description">НАЗВАНИЕ ВАЛЮТЫ</div>
          <div v-if="balance.name === 'demo'" class="name">
            Демобаланс (DEM)
          </div>
          <div v-else-if="balance.name === 'rub'" class="name">
            Российский рубль (RUB)
          </div>
          <div v-else-if="balance.name === 'usd'" class="name">
            Доллар США (USD)
          </div>
          <div v-else-if="balance.name === 'euro'" class="name">Евро (EUR)</div>
        </div>
      </div>
      <div class="block">
        <div class="info">
          <div class="description">БАЛАНС</div>
          <div class="name">{{ balance.balance }}</div>
        </div>
      </div>
      <router-link to="/payments/deposit" class="primary">
        Пополнить
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Country from "@/components/Profile/Settings/Country";
import City from "@/components/Profile/Settings/City";
export default {
  computed: {
    ...mapGetters("user", { avaliableBalances: "getAvaliableBalances" }),
    providers() {
      let accounts = this.user.accounts;
      let response = {};
      for (let i in accounts) {
        let account = accounts[i];
        response[account.provider] = account;
      }
      return response;
    },
    getTelegramName() {
      let provider = this.providers.tg;
      return `${provider.name} (${provider.profile_url.replace(
        "https://t.me/",
        "@"
      )})`;
    },
  },
  async created() {
    try {
      let { data } = await this.$http.get("user/meta");
      this.user = data.response;
      this.settings = this.user.settings;
      const info = this.user.info;
      if (info.city)
        this.selectedCity = {
          id: info.city.id,
          name_ru: info.city.name_ru,
          name_en: info.city.name_en,
        };
      if (info.country)
        this.selectedCountry = {
          id: info.country.id,
          name_ru: info.country.name_ru,
          name_en: info.country.name_en,
        };
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
  components: {
    Country,
    City,
  },
  data() {
    return {
      settings: {
        "2fa": false,
      },
      user: {
        email: "",
        info: {
          phone: "",
        },
      },
      selectedCountry: {},
      selectedCity: {},
      errors: {
        birthdate: null,
        city_id: null,
        country_id: null,
        last_name: null,
        phone: null,
        second_name: null,
        sex: null,
      },
      tg: "https://t.me/" + process.env.VUE_APP_TG_BOT,
    };
  },
  methods: {
    login(provider) {
      this.$http
        .post("auth/social", { provider })
        .catch(({ data }) => (window.location.href = data.response.redirect));
    },
    async verifyEmail() {
      try {
        let { data } = await this.$http.get(
          "user/verify?email=" + this.user.email
        );
        if (data.error) return this.$toast.error(data.error);
        this.$toast.info(data.response.message);
      } catch (error) {
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
    async save() {
      this.errors = {
        birthdate: null,
        city_id: null,
        country_id: null,
        last_name: null,
        phone: null,
        second_name: null,
        sex: null,
      };

      let info = this.user.info;
      info.sex = +info.sex;
      info.country_id = +info.country_id;
      info.city_id = +info.city_id;
      delete info.city;
      delete info.country;
      if (!info.country_id) delete info.country_id;
      if (!info.city_id) delete info.city_id;

      try {
        let { data } = await this.$http.put("user/info", info);
        this.$toast.success(data.response.message);
      } catch (error) {
        if (error?.status === 400) {
          for (let key in error.data.error) {
            this.errors[key] = error.data.error[key][0];
          }
        } else this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
    async toggle2fa() {
      try {
        let { data } = await this.$http.patch("user/2fa");
        this.$toast.success(data.response.message);
        this.settings["2fa"] = !this.settings["2fa"];
      } catch (error) {
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
    setCountry(country) {
      this.selectedCountry = country;
      this.user.info.country_id = country.id;
    },
    setCity(city) {
      this.selectedCity = city;
      this.user.info.city_id = city.id;
      console.log(this.user.info.city_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .text-error {
      display: block;
      margin-top: 5px;
      color: #f4511e;
      font-size: 15px;
      font-weight: 500;
    }
    .form-group {
      width: calc(50% - 10px);
      margin-bottom: 15px;
      @media (max-width: 768px) {
        width: 100%;
      }
      label {
        margin-bottom: 10px;
        display: block;
        font-size: 14px;
      }
      input.form-control,
      select {
        background: #f8fafc;
        border-radius: 6px;
        width: 100%;
        font-size: 16px;
        height: 50px;
        padding: 0 15px;
        color: #63788d;
        &::placeholder {
          color: rgba(#63788d, 0.5);
        }
      }
      .group {
        background: #f8fafc;
        border-radius: 6px;
        width: 100%;
        font-size: 16px;
        height: 50px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: calc(100% - 135px - 10px);
          color: #63788d;
          font-size: 16px;
          height: 50px;
          background-color: transparent;
          &::placeholder {
            color: rgba(#63788d, 0.5);
          }
        }
        button {
          background: #0085ff;
          height: 35px;
          width: 135px;
          box-shadow: 0px 15px 35px rgba(69, 99, 255, 0.25);
          border-radius: 6px;
          color: white;
          font-size: 15px;
          transition: 200ms ease;
          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }
    button.submit {
      background-color: #0085ff;
      padding: 15px 20px;
      border-radius: 6px;
      color: white;
      font-size: 18px;
      width: 150px;
      height: 50px;
      transition: 200ms ease;
      &:hover {
        cursor: pointer;
      }
      margin-bottom: 15px;
    }
  }
  .buttons {
    display: flex;
    a,
    button {
      margin-bottom: 15px;
      width: 120px;
      flex-direction: column;
      display: flex;
      height: 50px;
      margin-right: 10px;
      color: white;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      .description {
        font-size: 10px;
        margin-top: 3px;
        color: rgba(white, 0.5);
      }
      &.vk {
        background: #0077ff;
      }
      &.fb {
        background: #5079d2;
      }
      &.tg {
        background: #21aef4;
      }
      &.go {
        background: #ff6558;
      }
      transition: 200ms ease;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
  .fa2 {
    display: flex;
    align-items: center;
    margin: 15px 0 25px 0;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 54px;
        height: 54px;
        margin-right: 15px;
      }
      .message {
        color: #393838;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .description {
        color: #a3afc9;
        font-size: 14px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .name {
        font-weight: 500;
      }
      .toggle {
        background: #f4f6f9;
        border-radius: 100px;
        margin-left: 15px;
        width: 60px;
        height: 30px;
        align-items: center;
        display: flex;
        padding: 0 5px;
        cursor: pointer;
        &.active {
          justify-content: flex-end;
        }
        span {
          background: #0085ff;
          border-radius: 100px;
          width: 20px;
          height: 20px;
          display: block;
        }
      }
    }
  }

  .currency {
    border-radius: 6px;
    border: 1px solid #f8fafc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 15px;
    .block {
      display: flex;
      align-items: center;
      &:first-child {
        width: 260px;
      }
      .badge {
        width: 45px;
        height: 45px;
        border-radius: 6px;
        background: #0085ff;
        display: flex;
        align-items: center;
        font-size: 18px;
        color: white;
        margin-right: 15px;
        justify-content: center;
        &.success {
          background: #5ac406;
        }
      }
      .info {
        .description {
          color: #a3afc9;
          font-size: 14px;
          margin-bottom: 3px;
        }
        .name {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    a {
      border-radius: 6px;
      height: 45px;
      width: 120px;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.primary {
        background: #0085ff;
        color: #ffffff;
      }
      &.danger {
        background: rgba(255, 87, 87, 0.15);
        border: 1.5px solid rgba(255, 87, 87, 0.25);
        color: #ff5757;
      }
      transition: 200ms ease;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      a {
        margin-top: 15px;
        width: 100%;
      }
    }
  }
}
</style>
