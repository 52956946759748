<template>
  <ModelListSelect
    :list="cities"
    option-value="id"
    :custom-text="optionDisplayText"
    placeholder="Выберите город"
    v-model="selected"
    @searchchange="search"
  />
</template>

<script>
import { ModelListSelect } from "vue-search-select";
import { stringify } from "querystring";

export default {
  props: {
    selectedCity: Object,
  },
  components: {
    ModelListSelect,
  },
  async created() {
    this.search();
  },
  data() {
    return {
      selected: this.selectedCity,
      cities: [],
    };
  },
  methods: {
    async search(search = null) {
      const params = {};
      if (typeof search === "string") {
        if (search.length < 3) return;
        params.s = search;
      }

      try {
        const { data } = await this.$http.get(
          "geo/cities?" + stringify(params)
        );
        this.cities = data.response;
      } catch (error) {
        this.$toast.error(error?.message);
      }
    },
    optionDisplayText(country) {
      if (country?.name_ru && country?.name_en)
        return `${country.name_ru} - ${country.name_en}`;
      return country?.name_ru || country?.name_en;
    },
  },
  watch: {
    selectedCity(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("setCity", value);
    },
  },
};
</script>
